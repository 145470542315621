<template>
  <div class="rich-text" v-html="doc"></div>
</template>

<script>
export default {
  props:['doc'],
  mounted() {
  }
}

</script>

<style lang="scss">
.rich-text{
    font-size: 16px;
    line-height: 1.42;
    p{
        margin: 1em 0;
    }
    h1,h2,h3,h4,h5,h6{
        margin: 1.4em 0;
    }
}
</style>
